@font-face {
  font-family: "Montserrat-regular";
  src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf)
    format("truetype");
  font-weight: 600;
}
$light-blue: #43bcbf;
$dark-blue: #206f78;
$light-pink: #f4d4d3;
$dark-pink: #edad9f;
$beige: #efecdf;
$dark-beige: #b2afa0;

$Regular: "Montserrat-regular";
$Medium: "Montserrat-Medium";
$SemiBold: "Montserrat-SemiBold";

body {
  background-color: #faf6ef;
  margin: 0 30px;
  font-family: $Regular;
}
