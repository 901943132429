.header {
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 20%;
    margin-top: 50px;
  }
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  input {
    border: none;
    padding: 10px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #efecdf;
    color: #006f78;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: 0.3s ease-in;
    &:hover {
      background-color: #006f78;
      color: #efecdf;
    }
  }
}

.categories {
  display: flex;
  justify-content: center;
  form {
    margin: 0;
  }
  select {
    background-color: #ffffff;
    color: #006f78;
    font-family: $Medium;
    margin: 10px;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
}

.book-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-radius: 20px;
  background-color: white;
  padding: 10px 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 12px;
  .description {
    width: 90%;
  }
  img {
    height: 200px;
  }
  h2 {
    margin: 20px;
    color: #006f78;
    margin: 0;
  }
  p {
    margin: 0;
    .results {
      display: flex;
      flex-direction: column;
    }
  }
  .btn-fiche {
    border-radius: 12px;
    border: none;
    margin-top: 20px;
    background-color: #efecdf;
    color: #006f78;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    h2 {
      margin: 0;
      font-size: 15px;
    }
  }
}
.bloc-book {
  margin-left: 15px;
  h1 {
    font-size: 15px;
  }
  .blue {
    color: rgba(0, 0, 0, 0.704);
    font-family: $Medium;
    font-size: 12px;
  }
  .title {
    color: $dark-blue;
    font-family: $SemiBold;
  }
}

.container-saved-book {
  h2 {
    color: #006f78;
    text-align: center;
  }
  .saved-books {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 50px;
    gap: 30px;
    .avis {
      margin-top: 10px;
      p {
        color: $dark-pink;
      }
      input {
        border: none;
        margin-top: 3px;
        color: $dark-pink;
      }
      textarea {
        border: none;
      }
    }
    .btn-delete {
      border-radius: 12px;
      padding: 6px 10px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
  p {
    font-size: 15px;
  }
}
.btn-expand {
  color: $dark-blue;
  font-family: $SemiBold;
  border: none;
  background-color: white;
  margin: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    color: $dark-beige;
  }
}
.btn-trie {
  font-size: 11px;
  border-radius: 9px;
  padding: 6px 10px;
  margin-left: 10px;
  background-color: $dark-blue;
  font-weight: 600;
  color: $beige;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: $beige;
    color: $dark-blue;
  }
}
.btn {
  background-color: #efecdf;
  font-weight: 600;
  color: #006f78;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: $dark-blue;
    color: $beige;
  }
}
.star.full {
  color: $dark-beige;
}
.star.empty {
  color: $beige;
}
.star {
  margin-left: 3px;
}
.footer {
  color: $dark-blue;
  font-size: 15px;
  font-family: $Medium;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 25px;
}
